@import '../../../statics/variables/index.scss';

.container-pin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.pin-input {
  letter-spacing: 2px;
  text-align: center;
  caret-color: #fff;
  color: #ffffff;
  appearance: none;
  border-radius: 8px;
  background-color: rgba(148, 166, 205, 0.1);
  width: auto;
  height: auto;
  outline: none;
  border: none;
  font-family: $font-black;
  padding: 8px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 10px;
}

.pin-button {
  text-decoration: none;
  border: none;
  appearance: none;
  border-radius: 8px;
  font-size: 0.875em;
  padding: 10px 20px;
  cursor: pointer;
  font-family: $font-h3;
  transition: background-color 0.3s, color 0.3s;
  background-color: red; 
  color: white;

  &:not(:disabled) {
    background-color: green; 
    cursor: pointer;
  }


  &:disabled {
    background-color: red; 
    cursor: not-allowed;
  }
}

.pin-input::placeholder {
  color: rgba(178, 227, 255, 0.5); 
}

@media screen and (max-width: 460px) {
  .container-pin {
    width: 100%;
  }
  .pin-input {
    padding: 6px;
    border-radius: 6px;
  }

  .pin-button{
    font-size: 0.675em;
    padding: 6px;
    border-radius: 6px;
  }
}
