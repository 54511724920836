@import '../../statics/variables/index.scss';
@import 'react-toastify/dist/ReactToastify.css';
.auth-container {
  position: relative;
  height: 100vh;
  color: white;
}
.lucky-jet-design {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}
.auth-main {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 23px 0 24px 0 rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(
    circle at 0 0,
    rgba(148, 166, 205, 0.2),
    rgba(148, 166, 205, 0) 99%
  );
  position: relative;
  z-index: 2;
  &__form {
    display: flex;
    flex-direction: column;
    .form-top {
      padding: 10px 15px 10px 15px;
    }
    .form-title {
      font-family: $font-black;
      letter-spacing: 1px;
      line-height: 45px;
      font-size: 2.375em;
      text-align: center;
    }
    .form-subtitle {
      margin-top: 13px;
      font-family: $font-h2;
      font-size: 0.875em;
      text-align: center;
    }
    .form-password {
      margin-top: 20px;
    }
    .form-button-for-copy-promo {
      font-size: 0.875em;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: $font-h2;
      color: inherit;
      width: 340px;
      height: 46px;
      border-radius: 12px;
      background-color: rgba(148, 166, 205, 0.1);
      text-decoration: none;
      border: none;
      appearance: none;

      &__text {
        span {
          padding-left: 4px;
          font-size: 17px;
          font-family: $font-black;
        }
      }
      &__icon {
        width: 20px;
        height: 20px;
        background-image: url('../../statics/assets/images/copy.svg');
        background-size: cover;
      }
    }
    .form-button-for-copy-promo:active {
      background-color: hsla(123, 100%, 50%, 0.1);
    }

    .form-separator {
      padding: 0;
    }
    .form-bottom {
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .form-checkbox {
        display: flex;
        align-items: center;

        width: 345px;
        &__input {
          appearance: none;
          background-color: transparent;
          border: none;
          font-family: inherit;
          margin: 0;
          outline: none;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          border-radius: 6px;
          background-color: white;
          overflow: hidden;

          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.15s ease;
          &_true {
            position: relative;
            width: 8px;
            height: 10px;
            transform: rotate(-135deg);
            transform-origin: center;
            opacity: 1;
          }
          &_true::after {
            position: absolute;
            content: '';
            width: 8px;
            height: 3px;
            background-color: #000;
            border-radius: 10px;
          }
          &_true::before {
            position: absolute;
            content: '';
            width: 3px;
            height: 12px;
            background-color: #000;
            border-radius: 10px;
          }
        }
        &__label {
          margin-left: 10px;
          font-family: $font-h5;
          font-size: 0.625em;
          line-height: 12px;
          a {
            text-decoration: underline;
            color: inherit;
          }
        }
      }
      .button-registration {
        margin-bottom: 50px;
        font-family: $font-h1;
        margin-top: 15px;
        display: flex;
        width: 345px;
        height: 46px;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        position: relative;
        border: none;
        z-index: 2;
        font-size: 0.9375em;
        font-weight: 700;
        line-height: 1.27;
        letter-spacing: -0.2px;
        color: #fff;
        cursor: pointer;
        text-align: left;
        transition: box-shadow 0.15s ease, opacity 0.15s ease;
        box-shadow: 0 8px 42px 0 rgba(92, 49, 150, 0.29);
        background-image: linear-gradient(94deg, #d139bc, #303595 100%);
        &__arrow {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background-color: rgba(9, 15, 30, 0.3);
          margin-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          .button-arrow {
            width: 7px;
            height: 7px;
            position: relative;
            transform: rotate(135deg);
            margin-left: -3px;
          }
          .button-arrow::before {
            position: absolute;
            content: '';
            width: 7px;
            height: 2px;
            border-radius: 10px;
            background-color: #fff;
          }
          .button-arrow::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 7px;
            border-radius: 10px;
            background-color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .lucky-jet-design {
    padding-bottom: 30px;
  }
}
@media screen and (max-height: 760px) {
  .lucky-jet-design {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auth-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .auth-main {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 460px) {
  .auth-main {
    &__form {
      .form-top {
        padding: 15px 10px 10px 10px;
      }
      .form-title {
        line-height: 35px;
        font-size: 2em;
      }
    }
  }
}
