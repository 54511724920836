@import '../../statics/fonts/index.scss';

.lucky-jet-container.loading {
  background-color: #0d082200;
}

.lucky-jet-container.loading .lucky-jet-container__anim {
  opacity: 0;
}
.triangle-loading {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}
.lucky-jet-container__anim.hidden {
  display: none;
}

.lucky-jet-container {
  overflow-y: auto;
  color: white;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #0d0822;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  transition: opacity 0.3s ease;

  &__anim {
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;
    max-height: 336px;
    max-width: 600px;
    top: 10px;
    z-index: 970;
    pointer-events: none;
    .lucky-jet {
      height: 336px;
      width: 100%;
      transition: height 0.32s ease 0s;
      position: relative;
      overflow: hidden;
      border-radius: 13px;
      .lucky-jet-window {
        border-radius: 13px;
        pointer-events: none;
        margin-top: -107px;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }

  &__history {
    margin-top: -92px;
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .lucky-jet-container-history-header {
      min-width: 96%;
      min-height: 60px;
      justify-content: center;
      padding-top: 20px;
      padding-left: 10px;
      background: #1a123b;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 16px;
      border-radius: 14px 14px 0 0;
      width: 100%;
      max-width: 90%;
    }
    .past-rounds {
      padding: 10px;
      padding-top: 15px;
      padding-bottom: 25px;
      border-top: #0d0822 2px;
      min-width: 96%;
      min-height: 150px;
      width: 100%;
      max-width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: space-between;
      flex-wrap: wrap;
      background: linear-gradient(
        90deg,
        rgb(36, 29, 71) 0%,
        rgb(43, 35, 87) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.15) 0px -4px 24px;
    }
    &_title {
      font-size: 1.1em;
      font-family: GP-Medium;
      height: 100%;
    }
    &_item {
      margin-right: 10px !important;
      font-family: Arial-Bold;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      margin: auto;
      width: 60px;
      height: 28px;
      display: grid;
      padding: 0px 9px;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.0023em;
      color: rgb(250, 250, 250);
      border-radius: 7px;
      transition: background-color 0.4s ease;
    }
  }

  &__bets {
    max-width: 600px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container-for-buttons {
      margin-top: -5px;
      background: #1a123b;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 16px;
      max-width: 600px;
      min-width: 96%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
    }
    &_items {
      min-width: 96%;
      width: 100%;
      max-width: 90%;
      background: linear-gradient(
        90deg,
        rgb(36, 29, 71) 0%,
        rgb(43, 35, 87) 100%
      );
      box-shadow: rgba(0, 0, 0, 0.15) 0px -4px 24px;
      padding: 12px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 5px;
      border-radius: 14px;
      .jucky-jet-predictor-range {
        font-family: Arial-Bold;
        font-size: 1.1em;
        margin-left: 20px;
        text-align: left;
      }
      .lucky-jet-predictor-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border: #0d0822 2px solid;
        height: 55px;
        width: 180px;
        text-shadow: rgb(138, 138, 138) 0px 0px 20px;
        text-transform: uppercase;
        font-variant-numeric: tabular-nums;
        background: linear-gradient(
          263.87deg,
          rgb(32, 88, 209) 0%,
          rgb(41, 60, 230) 100%
        );
        box-shadow: rgba(37, 215, 247, 0.1) 0px 10px 20px,
          rgba(21, 10, 87, 0.1) 0px 10px 20px,
          rgb(32, 107, 194) 0px -2px 4px inset,
          rgba(44, 102, 228, 0.6) 0px -8px 8px inset;
        cursor: pointer;
        border-radius: 14px;
        &__chance {
          margin-right: 10px;
        }
      }
      .lucky-jet-predictor-violet {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border: #0d0822 2px solid;
        text-align: center;
        height: 55px;
        width: 180px;
        text-shadow: rgb(138, 138, 138) 0px 0px 20px;
        text-transform: uppercase;
        font-variant-numeric: tabular-nums;
        background: linear-gradient(
          263.87deg,
          rgb(148, 78, 245) 0%,
          rgb(92, 36, 252) 100%
        );
        box-shadow: rgba(151, 71, 255, 0.1) 0px 10px 20px,
          rgba(92, 36, 252, 0.1) 0px 10px 20px,
          rgb(148, 78, 245) 0px -2px 4px inset,
          rgba(148, 78, 245, 0.6) 0px -8px 8px inset;
        cursor: pointer;
        border-radius: 14px;
      }
      .lucky-jet-predictor-orange {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border: #0d0822 2px solid;
        text-align: center;
        height: 55px;
        width: 180px;
        text-shadow: rgb(138, 138, 138) 0px 0px 20px;
        text-transform: uppercase;
        font-variant-numeric: tabular-nums;
        background: linear-gradient(
          263.87deg,
          rgb(212, 138, 0) 0%,
          rgb(238, 196, 9) 100%
        );
        box-shadow: rgba(184, 181, 17, 0.1) 0px 10px 20px,
          rgba(226, 168, 9, 0.1) 0px 10px 20px,
          rgb(241, 152, 36) 0px -2px 4px inset,
          rgba(245, 186, 24, 0.6) 0px -8px 8px inset;
        cursor: pointer;
        border-radius: 14px;
      }
    }
    .lucky-jet-container-bets-header {
      margin-top: -10px;
      min-width: 96%;
      min-height: 60px;
      justify-content: center;
      padding-top: 20px;
      padding-left: 10px;
      background: #1a123b;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 16px;
      border-radius: 14px 14px 0 0;
      width: 100%;
      max-width: 90%;
    }

    &_title {
      font-size: 1.1em;
      font-family: GP-Medium;
    }
  }
}

@media (max-width: 460px) {
  .lucky-jet-container__history {
    .past-rounds {
      justify-content: center;
      align-content: space-around;
      min-height: 170px;
    }
    &_item {
      margin-right: 5px !important;
    }
  }
}
@media (max-width: 450px) {
  .lucky-jet-container__history {
    .past-rounds {
      justify-content: center;
      align-content: space-around;
      min-height: 170px;
    }
    &_item {
      margin-right: 5px !important;
    }
  }
  .lucky-jet-container__bets {
    .jucky-jet-predictor-range {
      font-size: 1em;
    }
  }
}
@media (max-width: 415px) {
  .lucky-jet-container__history {
    .past-rounds {
      justify-content: center;
      align-content: space-around;
      min-height: 170px;
    }
    &_item {
      margin-right: 5px !important;
    }
  }
  .lucky-jet-container__bets {
    .jucky-jet-predictor-range {
      font-size: 0.8em;
    }
  }
}

@keyframes translateAnimation {
  0% {
    transform: translate(-120px, 80px);
  }
  100% {
    transform: translate(450px, 30px);
  }
}

@keyframes gKBsdr {
  100% {
    background-position-x: -1e6px;
  }
}
@keyframes huAbwY {
  100% {
    background-position-x: -71px;
  }
}
@keyframes krObEU {
  100% {
    background-position-y: 71px;
  }
}
@keyframes scroll {
  100% {
    background-position: -3000px 0px;
  }
}
@keyframes scrolltop {
  100% {
    background-position: 0px 3000px;
  }
}
