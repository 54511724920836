@font-face {
  font-family: GP-Bold;
  src: url('../fonts/Gotham-Pro/gothampro_bold.ttf') format('truetype'),
    url('../fonts/Gotham-Pro/GothamPro-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: GP-Light;
  src: url('../fonts/Gotham-Pro/gothampro_light.ttf') format('truetype'),
    url('../fonts/Gotham-Pro/GothamPro-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: GP-Medium;
  src: url('../fonts/Gotham-Pro/gothampro_medium.ttf') format('truetype'),
    url('../fonts/Gotham-Pro/GothamPro-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: GP-Regular;
  src: url('../fonts/Gotham-Pro/gothampro_regular.ttf') format('truetype');
}

@font-face {
  font-family: GP-Black;
  src: url('../fonts/Gotham-Pro/Gotham_Pro_Black.ttf') format('truetype');
}

@font-face {
  font-family: Bowler;
  src: url('../fonts/Bowler.ttf') format('truetype');
}
@font-face {
  font-family: Arial-Bold;
  src: url('../fonts/Gotham-Pro/ofont.ru_Arial.ttf') format('truetype');
}
