@import '../../statics/variables/index.scss';
body {
  animation: fadeIn 1.5s ease-in;
}
.wrapper {
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url(../../statics/assets/images/bg.jpg) #060211;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
@keyframes loaderAnimation {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    background-color: black;
  }
  100% {
    opacity: 1;
  }
}
