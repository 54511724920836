.container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: url(../../../statics/assets/images/bg.jpg) #060211;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 70px 0;
  box-sizing: border-box;
}

@media (max-height: 570px) {
  .logo {
    display: none;
  }
}
.logo {
  margin-bottom: 50px;
  width: 70px;
}

.middle {
  position: relative;
  height: auto;
  transform: scale(0.85);
}

@media (max-width: 1920px), (max-height: 1280px) {
  .middle {
    transform: scale(0.6);
  }
}
/* 
@media (max-width: 600px), (max-height: 600px) {
  .middle {
    transform: scale(0.75);
  }
}

@media (max-width: 450px), (max-height: 450px) {
  .middle {
    transform: scale(0.66);
  }
} */

@media screen and (max-height: 820px) {
  .middle {
    margin-top: -20px;
    transform: scale(0.45);
  }
}
@media screen and (max-height: 760px) {
  .middle {
    margin-bottom: 20px;
    transform: scale(0.5);
  }
}
@media screen and (max-height: 710px) {
  .middle {
    margin-bottom: 0px;
    transform: scale(0.4);
  }
}

.animation {
  width: 238px;
  height: 238px;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-shine {
  width: 238px;
  height: 238px;
  max-width: 238px;
  max-height: 238px;
  background: #c86edb;
  animation: shinePulse 3s ease-in-out 0s infinite reverse;
  border-radius: 50%;
  box-shadow: 0 0 100px #8e46ff;
}

.bg-circle {
  width: 238px;
  height: 238px;
  background: linear-gradient(135deg, #b172ff, #8e46ff);
  border-radius: 300px;
}
.item {
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: ease-in-out;
}

.mask {
  border-radius: 50%;
  overflow: hidden;
  width: 238px;
  height: 238px;
  max-width: 238px;
  max-height: 238px;
  transform: translateZ(0);
}

.bg-man {
  top: -18%;
  left: -25%;
  width: 325px;
  height: 397px;
  background-position: -1px -1px;
  animation: manMove 1.5s ease-in-out 0s infinite;
}
.bg {
  background-image: url(../../../statics/assets/images/sprite.webp);
}

@keyframes manMove {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, -10px);
  }
}
.name {
  top: 100%;
  left: 50%;
}

.bg-l {
  top: -20px;
  left: -129px;
  width: 63px;
  height: 96px;
  background-position: -401px -1px;
  animation: letterJump 4s ease-in-out 0.5s infinite;
}

.bg-u {
  top: 8px;
  left: -81px;
  width: 56px;
  height: 67px;
  background-position: -401px -271px;
  animation: letterJump 4s ease-in-out 0.6s infinite;
}

.bg-c {
  top: -32px;
  left: -33px;
  filter: drop-shadow(0 -3px 5px #2f165866);
  width: 62px;
  height: 109px;
  background-position: -328px -99px;
  animation: letterJump 4s ease-in-out 0.7s infinite;
}

.bg-k {
  top: 1px;
  left: 29px;
  width: 54px;
  height: 74px;
  background-position: -401px -195px;
  animation: letterJump 4s ease-in-out 0.8s infinite;
}

.bg-y {
  top: -20px;
  left: 73px;
  width: 70px;
  height: 96px;
  background-position: -328px -210px;
  animation: letterJump 4s ease-in-out 0.9s infinite;
}

.bg-j {
  top: 80px;
  left: -87px;
  width: 61px;
  height: 94px;
  background-position: -401px -99px;
  animation: letterJump 4s ease-in-out 0.6s infinite;
}

.bg-e {
  top: 93px;
  left: -24px;
  width: 51px;
  height: 72px;
  background-position: -328px -308px;
  animation: letterJump 4s ease-in-out 0.75s infinite;
}

.bg-t {
  top: 81px;
  left: 22px;
  width: 71px;
  height: 96px;
  background-position: -328px -1px;
  animation: letterJump 4s ease-in-out 0.9s infinite;
}

@keyframes letterJump {
  0%,
  20%,
  100% {
    transform: translateY(0);
  }
  7% {
    transform: translateY(10px);
  }
  14% {
    transform: translateY(-20px);
  }
}

.bg-star {
  top: -10px;
  left: -10px;
  width: 47px;
  height: 51px;
  background-position: -401px -340px;
  animation: starPulse 3s ease-in-out 0s infinite;
}

.bg-star-2 {
  top: 140px;
  left: 208px;
}

.progress,
.progress__line {
  background: linear-gradient(90deg, #ff9c00, #ff8500);
}
.progress {
  width: 280px;
  max-width: calc(100% - 30px);
  height: 9px;
  border-radius: 9px;
  margin: 0px -30px 0;
  position: relative;
}
@media (max-height: 475px) {
  .progress {
    display: none;
  }
}

@keyframes starPulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
}
