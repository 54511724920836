@import '../../statics/variables/index.scss';

.deposit-container {
  position: relative;
  height: 100vh;
  color: white;
}
.lucky-jet-design {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}
.deposit-main {
  transition: opacity 0.3s ease;
  display: block;
  width: 100%;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 23px 0 24px 0 rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(
    circle at 0 0,
    rgba(148, 166, 205, 0.2),
    rgba(148, 166, 205, 0) 99%
  );
  position: relative;
  z-index: 2;

  &__form {
    display: flex;
    flex-direction: column;
    .form-top {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 10px 25px 10px 25px;
      .form-title {
        text-align: center;
        font-family: $font-black;
        letter-spacing: 1px;
        line-height: 45px;
        font-size: 2.375em;
      }
      .form-subtitle {
        text-align: center;
        margin-top: 13px;
        font-family: $font-h3;
        font-size: 0.875em;
        span {
          padding-left: 4px;
          font-family: $font-black;
        }
      }
      .button-continue {
        font-family: $font-h1;
        margin-top: 20px;
        display: flex;
        width: 235px;
        height: 46px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        position: relative;
        border: none;
        z-index: 2;
        font-size: 0.9375em;
        font-weight: 700;
        line-height: 1.27;
        letter-spacing: -0.2px;
        color: #fff;
        cursor: pointer;
        text-align: left;
        transition: box-shadow 0.15s ease, opacity 0.15s ease;
        box-shadow: 0 8px 42px 0 rgba(92, 49, 150, 0.29);
        background-image: linear-gradient(94deg, #d139bc, #303595 100%);
        &__arrow {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          background-color: rgba(9, 15, 30, 0.3);
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .button-arrow {
            width: 7px;
            height: 7px;
            position: relative;
            transform: rotate(135deg);
            margin-left: -3px;
          }
          .button-arrow::before {
            position: absolute;
            content: '';
            width: 7px;
            height: 2px;
            border-radius: 10px;
            background-color: #fff;
          }
          .button-arrow::after {
            position: absolute;
            content: '';
            width: 2px;
            height: 7px;
            border-radius: 10px;
            background-color: #fff;
          }
        }
      }
    }
    .form-separator {
      padding: 0;
      margin-top: 8px;
    }
    .form-bottom {
      padding-left: 25px;
      padding-right: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__text {
        font-size: 0.9375em;
        margin-top: 5px;
      }
      .button-deposit {
        font-family: $font-h1;
        margin-top: 20px;
        display: flex;
        width: 285px;
        height: 46px;
        border-radius: 20px;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        border: none;
        z-index: 2;
        font-size: 0.9375em;
        font-weight: 700;
        line-height: 1.27;
        letter-spacing: -0.2px;
        color: #000000;
        cursor: pointer;
        transition: box-shadow 0.15s ease, opacity 0.15s ease;
        box-shadow: 0 8px 42px 0 rgba(92, 49, 150, 0.29);
        background-image: linear-gradient(94deg, #ffda53, #c47d0b 100%);
        &__money {
          position: absolute;
          left: 15px;
          background: url(../../statics/assets/images/money-with-wings_1f4b8.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 60px;
          height: 60px;
          transform: rotate(15deg);
        }
        &__arrow {
          background: url(../../statics/assets/images/arrow.svg);
          background-position: center;
          background-size: contain;
          align-self: center;
          background-repeat: no-repeat;
          width: 30px;
          height: 30px;
          margin-left: 20px;
          margin-right: 15px;
        }
      }
      .form-openAi-logo {
        margin-top: 10px;
        margin-bottom: 10px;
        background: url(../../statics/assets/images/openai-vector-logo-seeklogo/openai-seeklogo.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100px;
        height: 50px;
      }
      /* .form-checkbox {
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        width: 345px;
        &__input {
          appearance: none;
          background-color: transparent;
          border: none;
          font-family: inherit;
          margin: 0;
          outline: none;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          border-radius: 6px;
          background-color: hsla(0, 0%, 100%, 0.1);
          overflow: hidden;
          cursor: pointer;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.15s ease;
        }
        &__label {
          margin-left: 10px;
          font-family: $font-h5;
          font-size: 10px;
          line-height: 12px;
          a {
            text-decoration: underline;
            color: inherit;
          }
        }
      } */
    }
  }
}

@media screen and (max-height: 900px) {
  .lucky-jet-design {
    padding-bottom: 30px;
  }
}
@media screen and (max-height: 760px) {
  .lucky-jet-design {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .deposit-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .deposit-main {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 460px) {
  .deposit-main {
    &__form {
      .form-top {
        .form-title {
          line-height: 35px;
          font-size: 2em;
        }
      }
    }
  }
}
